import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section, Em, Span, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Bold & Classic Barbershop
			</title>
			<meta name={"description"} content={"Mükemmel Üretim, Tek Seferde Tek Kesim"} />
			<meta property={"og:title"} content={"Bold & Classic Barbershop"} />
			<meta property={"og:description"} content={"Mükemmel Üretim, Tek Seferde Tek Kesim"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-2.jpg?v=2024-07-09T11:51:17.701Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="text">
				Bold & Classic Barbershop
			</Override>
		</Components.Header>
		<Components.Hero background="linear-gradient(0deg,rgba(4, 16, 41, 0.74) 0%,rgba(4, 16, 41, 0.79) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-1.jpg?v=2024-07-09T11:51:17.706Z) center/cover repeat scroll padding-box">
			<Override slot="link" display="none" />
			<Override slot="text">
				Mükemmel Üretim, Tek Seferde Tek Kesim
			</Override>
			<Override slot="text1" font="--lead">
				Bold & Classic Barbershop'ta, şık görünmenizi ve kendinizi güvende hissetmenizi sağlamak için tasarlanmış kapsamlı bakım hizmetleri sunuyoruz. Yetenekli berberlerimiz, ister hızlı bir düzeltme ister tam bir stil revizyonu istiyor olun, en yüksek hizmet kalitesini sunmaya kendini adamıştır. Zarif bakım dünyamıza adım atın ve mükemmelleştirilmiş berberlik sanatını keşfedin.
			</Override>
		</Components.Hero>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11:51:17.691Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-2.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Saç kesimlerimiz benzersiz tarzınıza ve tercihlerinize göre tasarlanarak, size mükemmel bir şekilde yakışan bir görünümle ayrılmanızı sağlar
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline3">
						Saç Kesimi
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base" color="--darkL2">
						Klasik Kesim: Modası asla geçmeyen, zamansız bir saç kesimi. Geleneğe değer verenler için mükemmel.
						<br />
						<br />
						Modern Tarz: En yeni tarz ve teknikleri içeren bir saç kesimi ile trendi takip edin.
						<br />
						<br />
						Çocuk Kesimleri: Ailenin genç üyelerine özel, kendilerini özel hissetmeleri için tasarlanmış eğlenceli ve şık saç kesimleri.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11:51:17.691Z"
						display="block"
						width="100%"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						max-height="400px"
						srcSet="https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-3.jpg?v=2024-07-09T11%3A51%3A17.691Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 90px 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
					order="-1"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Uzman tıraş hizmetlerimizle rahatlığın zirvesini yaşayın
					</Text>
					<Text margin="2rem 0px 0 0px" color="--dark" font="--headline3">
						Tıraş
					</Text>
					<Text margin="1rem 0px 0px 0px" font="--base" color="--darkL2">
						Sıcak Havlulu Tıraş: Cildinizi pürüzsüz ve tazelenmiş bırakan geleneksel sıcak havlulu tıraşın keyfini çıkarın.
						<br />
						<br />
						Sakal Düzeltme: Hassas düzeltme hizmetlerimizle sakalınızın keskin ve bakımlı görünmesini sağlayın.
						<br />
						<br />
						Tıraş Makinesiyle Tıraş: Profesyonel tıraş bıçağı tekniğimizle yakın ve temiz bir tıraşın keyfini çıkarın.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11:51:17.746Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11%3A51%3A17.746Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11%3A51%3A17.746Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11%3A51%3A17.746Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11%3A51%3A17.746Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11%3A51%3A17.746Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11%3A51%3A17.746Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/2-4.jpg?v=2024-07-09T11%3A51%3A17.746Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						Özel tedavilerimizle saçınızın sağlığını ve görünümünü iyileştirin
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="--headline3">
						Saç Bakımları
					</Text>
					<Text margin="0px 0px 0px 0px" font="--base\n\n" color="--darkL2">
						Saç Derisi Bakımı: Saç derinizi besleyen ve sağlıklı saç büyümesini destekleyen canlandırıcı bir bakım.
						<br />
						<br />
						Saç Onarımı: Yoğun onarım bakımlarımızla hasarlı saçları onarın ve gençleştirin.
						<br />
						<br />
						Renk Hizmetleri: Hafif vurgulardan cesur renklere kadar, tarzınıza uygun çeşitli renklendirme seçenekleri sunuyoruz.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/668d0473f0cb1d0025f6fdcf/images/1-3.jpg?v=2024-07-09T11:51:17.711Z) center center/cover no-repeat"
			min-height="60vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					<Em>
						Hazır mısınız?
					</Em>
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="60%"
				>
					Cesur ve Klasik Berber Dükkanı'nda klasik çekiciliğin ve modern zarafetin hakim olduğu bir dünyaya adım atın.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--light"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					<Span
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Bize Katılın!
					</Span>
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});